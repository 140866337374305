<template>
  <div v-show="!empty">
    <CommonListLabel
      v-if="placement === 'side'"
      :icon="IconQA"
      :label="label"
      :link="`${currentPaper}/${labelLinkUrl}`"
    />
    <CommonDivider />
    <ul class="text-red">
      <template v-for="(item, index) in items" :key="index">
        <li>
          <QATeaser
            :item="item"
            :index="index"
            :type="type"
            :placement="placement"
          />
        </li>
        <CommonDivider />
      </template>
    </ul>
    <div v-if="showMore && moreToShow" class="flex justify-center">
      <CommonButton @click.native="loadMore">
        {{ $t('ShowMore') }}
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconQA from '@/assets/icons/ministersvar.svg?url'

const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  type: {
    type: String,
    default: 'minister',
  },
  placement: {
    type: String,
    default: 'side',
  },
  label: {
    type: String,
    default: 'MinisterQA',
  },
  labelLink: {
    type: String,
    default: 'Routes.QA',
  },
  showMore: {
    type: Boolean,
    default: false,
  },
})

const { count, type, placement, label, labelLink, showMore } = toRefs(props)
const currentPage = ref(1)

const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const labelLinkUrl = computed(() => {
  return nuxtApp.$t(labelLink.value)
})

const currentPaper = computed(() => {
  if (
    indexStore.currentPaper &&
    parseInt(indexStore.currentPaper?.RecordId) !== 1
  ) {
    return '/' + indexStore.currentPaper?.Url
  } else {
    return ''
  }
})

const moreToShow = computed(() => {
  return items.value.length % count.value === 0
})

const empty = computed(() => {
  return !(items.value && items.value.length > 0)
})

const { data: items, refresh } = await useAsyncData(async () => {
  const currentPaperId = indexStore.currentPaper?.RecordId ?? 1

  return await nuxtApp.$api.content.QA(
    currentPaperId,
    type.value,
    count.value * currentPage.value
  )
})
const loadMore = () => {
  currentPage.value += 1
  refresh()
}
</script>
